import React, { useCallback, useEffect, useState } from 'react';
import useGetComponent from 'legacy/lib/api/hooks/useGetComponent';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Spinner from 'legacy/app/components/help/Spinner';
import { FormProvider, useForm } from 'react-hook-form';
import useGetAssociatedComponents from 'legacy/lib/api/hooks/useGetAssociatedComponents';
import { Component } from 'legacy/lib/api/types/Component';
import getPreloadValues from './utils/getPreloadValues';
import useGetAddressByCode from 'legacy/lib/api/hooks/useGetAddressByCode';
import { Summary } from 'legacy/lib/api/types/Summary';
import {
	TWhatChangedComponent,
	TWhatChangedComponentPayload,
} from 'legacy/lib/api/types/WhatChangedComponent';
import useComponentRecalculate from 'legacy/lib/api/hooks/useComponentRecalculate';
import convertObjectPropsToLowercase from 'legacy/utilities/convertObjectPropsToLowercase';
import normalizeRecalculateDataPayload from './utils/normalizeRecalculateDataPayload';
import normalizeRecalculateDataResponse from './normalizeRecalculateDataResponse';
import useDeleteFile from 'legacy/lib/api/hooks/useDeleteFile';
import useUpdateFile from 'legacy/lib/api/hooks/useUpdateFile';
import useUploadFile from 'legacy/lib/api/hooks/useUploadFile';
import {
	displayAlertError,
	displayAlertLoader,
	displayAlertSuccess,
	hideAlertLoader,
} from 'legacy/utilities/Response';
import MSG from 'legacy/defaults/Message';
import getItemOrMainComponentDescription from 'legacy/utilities/getItemOrMainComponentDescription';
import { AxiosError } from 'axios';
import URI from 'legacy/defaults/RoutesDefault';
import { UserError } from 'legacy/lib/api/types/UserError';
import normaliseComponentPayload from 'legacy/utilities/normalizeComponentPayload';
import { TFormValues } from './types/TFormValues';
import useEditComponent from 'legacy/lib/api/hooks/useEditComponent';
import { useQueryClient } from '@tanstack/react-query';
import SecureBootstrapButton from 'legacy/app/components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';
import LegacyTabs from 'legacy/templates/modules/item/component-add-for-component-refactor/ItemAddComponentAdd';
import ComponentTabs, { TTabOption } from './partials/Tabs';
import Info from './partials/Info';
import Header from './partials/Header';

const ComponentPage = () => {
	const { componentId, itemId, id: projectId } = useParams();

	const [whatChanged, setWhatChanged] = useState<
		TWhatChangedComponentPayload | object
	>({});

	const navigate = useNavigate();

	const { mutateAsync: deleteFile } = useDeleteFile();

	const { mutateAsync: updateFile } = useUpdateFile();

	const { mutateAsync: uploadImage } = useUploadFile();

	const { mutateAsync: editComponent, isLoading: isEditing } =
		useEditComponent();

	const [subcomponents, setSubcomponents] = useState<Component[]>([]);

	const [activeTab, setActiveTab] = useState('info');

	const [updatedData, setUpdatedData] = useState<{ [key: string]: any }>({});

	const [description, setDescription] = useState('');

	const [numberOfDocuments, setNumberOfDocuments] = useState(0);

	const [multipleCompsRecalculating, setMultipleCompsRecalculating] =
		useState(false);

	const queryClient = useQueryClient();

	const { data: recalculatedData, isFetching: isRecalculating } =
		useComponentRecalculate(Number(componentId), JSON.stringify(whatChanged), {
			enabled: Object.values(whatChanged).length > 0,
		});

	const {
		data: component,
		isLoading,
		error,
	} = useGetComponent(componentId as string);

	const {
		data: associatedComps,
		isLoading: isFetchingAssociatedComps,
		error: errorAssociatedComps,
	} = useGetAssociatedComponents(
		{
			projectId: projectId as string,
			itemId: itemId as string,
			component: component?.[0] as Component,
		},
		{
			enabled: !!component,
		}
	);

	const { data: defaultAddress, isFetching: isGettingAddress } =
		useGetAddressByCode(
			component?.[0].shipto as string,
			component?.[0].shiptoaddrtype as number
		);

	const methods = useForm<TFormValues>();

	const { setValue } = methods;

	useEffect(() => {
		if (associatedComps) {
			setSubcomponents(associatedComps);
		}
	}, [associatedComps]);

	useEffect(() => {
		const addSupplier = async () => {
			if (
				(whatChanged as TWhatChangedComponentPayload).whatChanged ===
					TWhatChangedComponent.cwcVendor &&
				component?.[0] &&
				recalculatedData &&
				!isRecalculating
			) {
				const supplier = methods.watch('supplier');
				const normalizedRecalculatedData =
					normalizeRecalculateDataResponse(recalculatedData);
				try {
					await editComponent(
						normaliseComponentPayload({
							...component[0],
							id: component[0].id,
							supplier,
							supdep: normalizedRecalculatedData.supDep,
							bterm1: normalizedRecalculatedData.bTerm1,
							bterm2: normalizedRecalculatedData.bTerm2,
							bterm3: normalizedRecalculatedData.bTerm3,
							comptype: normalizedRecalculatedData.compType,
							markup: normalizedRecalculatedData.markup,
							useterms: normalizedRecalculatedData.useTerms,
						})
					);
				} catch {
					// do nothing for now
				}
			}
		};
		if (recalculatedData) {
			Object.entries(
				convertObjectPropsToLowercase(
					normalizeRecalculateDataResponse(recalculatedData)
				)
			).forEach(([name, value]) => {
				if (name === 'id') {
					return;
				}
				return setValue(name as keyof TFormValues, value);
			});
			addSupplier();
		}
	}, [
		setValue,
		isRecalculating,
		recalculatedData,
		whatChanged,
		component,
		methods,
		editComponent,
	]);

	useEffect(() => {
		const preloadValues = async () => {
			if (component && defaultAddress) {
				const preloadValues = await getPreloadValues(
					component[0],
					defaultAddress as Summary[]
				);
				setDescription(preloadValues.description.html);

				Object.entries(preloadValues).forEach(([name, value]) =>
					setValue(name as keyof TFormValues, value)
				);
			}
		};
		preloadValues();
	}, [component, setValue, defaultAddress]);

	const handleRecalculate = (
		whatPropertyChanged: `${TWhatChangedComponent}`
	) => {
		const newWhatChanged = {
			whatChanged: whatPropertyChanged,
			...normalizeRecalculateDataPayload(methods.getValues()),
		};

		// The query will refetch whenever whatChanged changes. But if there hasn´t been any change, we trigger a manual refetch
		if (JSON.stringify(whatChanged) === JSON.stringify(newWhatChanged)) {
			queryClient.resetQueries([
				component?.[0].id,
				'recalculateComponent',
				JSON.stringify(newWhatChanged),
			]);
		}
		setWhatChanged(newWhatChanged);
	};

	const updateComponents = useCallback(
		(components: Component[]) => setSubcomponents(components),
		[]
	);

	const handleImage = async () => {
		const image = methods.watch('image');

		const componentToEdit = component?.[0];
		if (componentToEdit?.primaryImageId && !image) {
			await deleteFile(componentToEdit.primaryImageId);
			return '';
		} else if (componentToEdit?.primaryImageId && image) {
			await updateFile({
				id: componentToEdit?.primaryImageId,
				file: image,
			});
			return componentToEdit?.primaryImageId;
		}

		if (image) {
			const uploadedImage = await uploadImage({
				file: image,
				ObjectType: 'item',
				ObjectId: componentToEdit?.id as number,
				FileType: 1,
			});
			return uploadedImage.id;
		}
	};

	const onSubmit = async (data: TFormValues) => {
		displayAlertLoader(MSG.loading.update.msg);
		try {
			const description = await getItemOrMainComponentDescription(
				data.itemName,
				data.description
			);

			const uploadedImage = await handleImage();

			const values: Partial<TFormValues> = structuredClone(data);

			delete values.image;

			delete values.description;

			const payload = normaliseComponentPayload(values as unknown as Component);

			await editComponent({
				...description,
				...payload,
				supplier: data.supplier || null,
				id: Number(componentId),
				primaryImageId: uploadedImage || '',
				...updatedData,
			});

			await Promise.all(
				subcomponents.map(async (comp) => {
					const selectedVendorName = values.vendorName ?? null;
					const selectedSupplier = values.supplier ?? null;

					// If the user deselects the vendor dropdown AND the subComponent
					// has the same value as the mainComponent, then set it to null.
					// Otherwise, set it "as-is" to the value of the subComponent.
					const vendorName =
						!selectedVendorName &&
						comp.vendorName === component?.[0]?.vendorName
							? null
							: comp.vendorName;

					const supplier =
						!selectedSupplier && comp.supplier === component?.[0]?.supplier
							? null
							: comp.supplier || null;

					const subComPayload = {
						...comp,
						vendorName,
						supplier,
					};

					await editComponent(normaliseComponentPayload(subComPayload));
				})
			);

			await queryClient.resetQueries([
				[itemId, projectId, 'components'],
				[itemId, 'items'],
			]);

			hideAlertLoader();
			displayAlertSuccess(`Component(s) edited successfully!`);

			setTimeout(
				() =>
					navigate(
						generatePath(URI.project.newItemEdit, {
							id: projectId,
							itemId,
						})
					),
				1000
			);
		} catch (error) {
			hideAlertLoader();
			displayAlertError(
				(error as AxiosError<UserError>)?.response?.data?.userError ||
					'There was an error saving the information, please try again'
			);
		}
	};

	const submitMethods = methods.handleSubmit(onSubmit);

	if (isLoading || isFetchingAssociatedComps || isGettingAddress) {
		return <Spinner />;
	}

	if (error || errorAssociatedComps) {
		return null;
	}

	return (
		<FormProvider
			resetField={methods.resetField}
			getFieldState={methods.getFieldState}
			handleSubmit={methods.handleSubmit}
			watch={methods.watch}
			setError={methods.setError}
			getValues={methods.getValues}
			reset={methods.reset}
			setValue={methods.setValue}
			clearErrors={methods.clearErrors}
			control={methods.control}
			register={methods.register}
			unregister={methods.unregister}
			setFocus={methods.setFocus}
			trigger={methods.trigger}
			formState={methods.formState}
		>
			<Header
				disableButtons={
					isEditing || isRecalculating || multipleCompsRecalculating
				}
				onSubmit={methods.handleSubmit(onSubmit)}
			/>
			<ComponentTabs
				numberOfDocuments={numberOfDocuments}
				activeTab={activeTab as TTabOption}
				toggleTab={(newTab) => setActiveTab(newTab)}
			/>
			{activeTab === 'info' && (
				<Info
					multipleCompsRecalculating={multipleCompsRecalculating}
					setMultipleCompsRecalculating={setMultipleCompsRecalculating}
					isRecalculating={isRecalculating}
					component={component?.[0] as Component}
					onSubmit={methods.handleSubmit(onSubmit)}
					handleRecalculate={handleRecalculate}
					updateComponents={updateComponents}
					subcomponents={subcomponents}
					description={description}
				/>
			)}
			{activeTab !== 'info' && (
				<LegacyTabs
					updatedData={updatedData}
					component={component}
					subcomps={subcomponents}
					onChildrenDataChange={setUpdatedData}
					activeMenu={activeTab}
					setNumberOfFiles={setNumberOfDocuments}
				/>
			)}
			<div className="tw-p-8 bg-ivory gradient light">
				<SecureBootstrapButton
					onClick={submitMethods}
					disabled={isRecalculating || isEditing || multipleCompsRecalculating}
					attributeNo={14}
					attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
					variant="primary"
				>
					Save
				</SecureBootstrapButton>
			</div>
		</FormProvider>
	);
};

ComponentPage.displayName = 'ComponentPage';

export default ComponentPage;
