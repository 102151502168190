import { useQuery } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { getBillingInfo } from 'api/resources/clients';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetBillingInfo = (userName: string, enabled: boolean) => {
	const query = useQuery({
		queryKey: [ECacheKeys.Clients, 'billingInfo', userName],
		queryFn: async () => {
			try {
				return await getBillingInfo(userName);
			} catch (error: unknown) {
				const axiosError = error as AxiosError;
				if (axiosError.response && axiosError.response.status === 404) {
					return null;
				}
				throw error;
			}
		},
		enabled: enabled && !!userName,
		retry: false,
	});

	return query;
};
