import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { displayAlert } from 'legacy/utilities/Response';
import { useRunReport } from 'legacy/hooks';

import {
	getCreditCardReconciliations,
	voidCreditCardReconciliation,
} from '../api';
import ConfirmModal from '../ConfirmModal';
import ConfirmationMessage from './ConfirmationMessage';
import Grid from './Grid';
import SecureContent from 'legacy/app/components/security/SecureContent';
import {
	SECURITY_ATTRIBUTE_TYPES,
	useHasAccess,
} from 'legacy/app/context/security';
import { useAccounts } from '../hooks';
import { useHeader } from 'legacy/hooks/useHeader';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import { useFeature } from 'use-feature';
import URI from 'legacy/defaults/RoutesDefault';
import dayjs from 'dayjs';

export default function ReconcileHistory() {
	const [isConfirmationShowing, setIsConfirmationShowing] = useState(false);
	const [voidItem, setVoidItem] = useState(null);

	const runReport = useRunReport();
	const queryClient = useQueryClient();
	const hasAccess = useHasAccess(49, SECURITY_ATTRIBUTE_TYPES.DenyAccess);
	const showFeature = useFeature(FeatureFlags.ReconcileCC);

	useHeader({
		title: 'Credit Card',
		breadcrumbs: [
			{
				text: 'Accounts Payable',
				to: URI.accountsPayable.creditCard.list,
			},
			{
				text: 'Credit Card',
			},
		],
		tabs: showFeature
			? [
					{
						label: 'Reconcile',
						to: URI.accountsPayable.creditCard.reconcile,
						active: false,
						id: 'credit-card-reconcile',
					},
					{
						label: 'Reconcile History',
						to: URI.accountsPayable.creditCard.history,
						active: true,
						id: 'credit-card-reconcile-history',
					},
			  ]
			: [],
		enableBackButton: false,
	});

	const accountsQuery = useAccounts();

	const reconciliations = useQuery({
		enabled: hasAccess,
		queryKey: ['accounts-payable', 'credit-card', 'reconcile', 'history'],
		queryFn: getCreditCardReconciliations,
	});

	const voidMutation = useMutation(voidCreditCardReconciliation, {
		onSuccess: () => {
			queryClient.invalidateQueries(['accounts-payable', 'credit-card']);
		},
		onError: (error) => {
			const message =
				error?.response?.data?.userError ||
				'Only the most recent reconciliation can be deleted.';
			displayAlert('danger', message);
		},
	});

	const onConfirmationCancel = () => {
		setIsConfirmationShowing(false);
	};

	const onConfirmationConfirm = () => {
		const { ccaccount, statementdate } = voidItem;

		voidMutation.mutate({
			CreditCardAccount: ccaccount,
			StatementDate: statementdate,
		});
		setIsConfirmationShowing(false);
	};

	const onDownload = ({ ccaccount, statementdate }) => {
		const statementDate = dayjs(new Date(statementdate)).format('YYYY-MM-DD');
		runReport({
			label: 'Credit Card Reconciliation',
			reportId: 1176,
			reportFormatId: 1352,
			runReportParameters: [
				{
					parameterId: 735,
					value: `${ccaccount},${statementDate}`,
				},
			],
		});
	};

	const onVoid = ({ ccaccount, statementdate }) => {
		setVoidItem({ ccaccount, statementdate });
		setIsConfirmationShowing(true);
	};

	return (
		<>
			<Helmet>
				<title>Reconcile Credit Card History | Design Manager</title>
			</Helmet>
			<SecureContent
				attributeNo={61}
				attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
			>
				<SecureContent.NoAccess>
					<div className="content-padding min-height has-action-bar mt-2">
						<SecureContent.GenericNoAccess />
					</div>
				</SecureContent.NoAccess>
				<SecureContent.HasAccess>
					{accountsQuery.data && (
						<div className="content-padding min-height has-action-bar mt-2">
							<Grid
								accountsQuery={accountsQuery}
								onDownload={onDownload}
								onVoid={onVoid}
								query={reconciliations}
							/>
						</div>
					)}
					<ConfirmModal
						isShowing={isConfirmationShowing}
						onCancel={onConfirmationCancel}
						onConfirm={onConfirmationConfirm}
					>
						<ConfirmationMessage />
					</ConfirmModal>
				</SecureContent.HasAccess>
			</SecureContent>
		</>
	);
}

ReconcileHistory.displayName = 'ReconcileHistory';
