import { apiClient } from 'api/apiClient';

const RESOURCE = '/clients';

interface IRecurlyAddress {
	address1?: string | null;
	address2?: string | null;
	city?: string | null;
	state?: string | null;
	zip?: string | null;
	country?: string | null;
	phone?: string | null;
}

interface IRecurlyAccount {
	accountCode?: string | null;
	state?: string | null;
	username?: string | null;
	email?: string | null;
	ccEmails?: string | null;
	firstName?: string | null;
	lastName?: string | null;
	companyName?: string | null;
	vatNumber?: string | null;
	address?: IRecurlyAddress | null;
	acceptLanguage?: string | null;
	hostedLoginToken?: string | null;
	createdAt: string;
	closedAt?: string | null;
	accountUrl?: string | null;
	adjustmentsUrl?: string | null;
	invoicesUrl?: string | null;
	subscriptionsUrl?: string | null;
	transactionsUrl?: string | null;
}

export const getBillingInfo = async (
	userName: string
): Promise<IRecurlyAccount> => {
	const { data } = await apiClient.get(
		`${RESOURCE}/getbillinginfo/${userName}`,
		{}
	);
	return data;
};
