import React, { useState, useMemo } from 'react';
import PricingTabChoiceSelector, {
	TSelectedChoice,
} from './PricingTabChoiceSelector';
import { Item } from 'legacy/lib/api/types/Item';
import PricingTabComponent from './PricingTabComponent';
import { ECompIdentifiers } from 'legacy/lib/api/types/CompView';
import getCompanyTypeMap from 'legacy/utilities/getComponentTypeMap';
import Spinner from 'legacy/app/components/help/Spinner';
import useGetCompanyInfo from 'legacy/lib/api/hooks/useGetCompanyInfo';
import { Component } from 'legacy/lib/api/types/Component';
import { ColumnDef } from '@tanstack/react-table';
import Table from './Table';
import { Company } from 'legacy/lib/api/types/Company';
import { currencyFormat } from 'legacy/helpers/Number';
import { truncateCharCount } from 'legacy/helpers/String';

const PricingTab = ({
	itemToEdit,
	components,
}: {
	itemToEdit: Item;
	components: Component[];
}) => {
	const { data: company, isLoading } = useGetCompanyInfo();

	const [selectedChoice, setSelectedChoice] = useState<TSelectedChoice>('est');

	const changeSelectedChoice = (newChoice: TSelectedChoice) =>
		setSelectedChoice(newChoice);

	const columns = useMemo<ColumnDef<Component>[]>(
		() => [
			{
				accessorKey: 'comp',
				header: '#',
				size: 50,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'desc',
				header: 'Description',
				size: 300,
				enableSorting: false,
				cell: (info) => truncateCharCount(info.getValue(), 100),
			},
			{
				accessorFn: (col) =>
					company ? getCompanyTypeMap(company as Company)[col.comptype] : null,
				header: 'Type',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.estcost, '$'),
				header: 'Est. Cost',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.actcost, '$'),
				header: 'Act. Cost',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.estprice, '$'),
				header: 'Est. Price',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.billprice, '$'),
				header: 'Billing Price',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.actprice, '$'),
				header: 'Act. Price',
				enableSorting: false,

				cell: (info) => info.getValue(),
			},
		],
		[company]
	);
	if (isLoading) {
		return <Spinner />;
	}

	return company ? (
		<div className="tw-min-h-[calc(100vh-360px)] tw-pt-10 tw-pb-10">
			<PricingTabChoiceSelector
				itemToEdit={itemToEdit}
				changeSelectedChoice={changeSelectedChoice}
				selectedChoice={selectedChoice}
			/>
			<div className="tw-mt-8 tw-pr-16">
				{Object.values(ECompIdentifiers).map((comp, index) => (
					<PricingTabComponent
						type={getCompanyTypeMap(company)[index]}
						key={comp}
						showHeader={index === 0}
						itemToEdit={itemToEdit}
						component={comp}
						selectedChoice={selectedChoice}
					/>
				))}
			</div>
			<div className="tw-pl-16 tw-mt-8 tw-pr-16">
				<Table columns={columns} data={components} />
			</div>
		</div>
	) : null;
};

PricingTab.displayName = 'PricingTab';

export default PricingTab;
