import { HttpWorker, HttpWorkerConfigurationFactory } from './HttpWorker';
import { getCookie } from '../../utilities/Auth';
import { uniqBy } from 'lodash';
import { Component } from './types/Component';
import { Company } from './types/Company';
import { TProcessor } from './types/PaymentProcessors';
import { PaymentSessionType } from './types/PaymentSessionType';
import { TPaymentProcessorResponse } from './types/PaymentProcessorResponse';
import { TPaymentProcessorOnboardingSession } from './types/PaymentProcessorOnboardingSession';
import { IClippedItemsResponse } from './types/ClippedItems';
import { TDesignPayRates } from './types/DesignPayRates';
import { TBusinessSummaryResponse } from './types/BusinessSummary';
import { TWhatChangedComponentResponse } from 'legacy/lib/api/types/WhatChangedComponent';
import { Item } from './types/Item';
import { TProjectSchedule } from './types/ProjectSchedule';
import { IItemImages, TItemOrder } from 'legacy/lib/api/types/ItemImages';
import {
	type ICreditCardReconciliationTotals,
	type ICreditCardReconciliationHistory,
} from './types/Reconcile';

interface HttpService {
	httpWorker: HttpWorker;
}
interface ICheckInventoryParams {
	stockNo: string;
	whCode: string;
	newQty: number;
}

interface ICreateItemFromInventoryParams {
	compQty: number;
	locationCode?: string;
	projectId: string;
	stockNo: string;
	strGroupName: string;
	useInventorySellPrice: boolean;
	warehouseCode: string;
}

interface IGetCompanyReturn {
	whshipto: string;
	fiscaloffset: number;
	compviewm: string;
	compviewd: string;
	compviewf: string;
	compviewi: string;
	compviewl: string;
	compviewo: string;
	serialNumber: string;
}

interface ICheckInventoryResponse {
	isOverCommitted: boolean;
	message: string;
}

interface IBudgetCheck {
	passed: boolean;
	isWarning: boolean;
	reasonMessage: string;
}

interface ICreateItemFromInventoryResponse {
	budgetCheck: IBudgetCheck;
	newItemId: number;
	newCompId: number;
	primaryImageId: string;
}

interface IGLAccount {
	account: string;
	accountn: string;
	banknum?: string | null;
	cashcloseddt: string;
	checknum?: string | null;
	closedfmo?: string | null;
	dept: string;
	fixedType: number;
	nextchecknum: string;
	payroll: boolean;
	ppbankname?: string | null;
	ppfileloc?: string | null;
	ppincludehc: boolean;
	ppincludevoids: boolean;
	specacct: number;
	supplier: string;
	type: number;
}

interface IRecentProject {
	proj: string;
	projectName: string;
	dateOpened: string;
	budgetTotal: number;
	clientCode: string;
	clientName: string;
	totalPaymentsInFromClient: number;
	totalEstimatedPriceToClient: number;
	totalPaidOutToVendors: number;
	totalEstimatedCostFromVendors: number;
}

interface IInsurance {
	vendor: string;
	insuranceno: number;
	policytype: string | null;
	carrier: string | null;
	effectivedt: string | null;
	expirationdt: string | null;
	policyno: string | null;
	active: boolean | null;
	ssmaTimeStamp: string | null;
}

export type TReconciliation = {
	userName: string;
	statementdate: string;
	usercode: number;
	bookbalance: number;
	cashaccount: string;
	computerbalance: number;
	computerdate: string;
	latest: boolean;
	statementbalance: number;
};

interface IPosPayment {
	accountn?: string;
	type: string;
	acct?: string;
	datatype?: number;
	seqnum?: number;
	notonpos?: boolean;
	alwayscv?: boolean;
	undeposited?: boolean;
}

interface IWebSettings {
	id?: string;
	serialNumber?: string;
	companyName?: string;
	address?: string;
	city?: string;
	state?: string;
	zip?: string;
	phone?: string;
	fax?: string;
	email?: string;
	websiteUrl?: string;
	bannerImageUrl?: string;
	greetingText?: string;
	aboutUsImageUrl?: string;
	aboutUsText?: string;
	hasStore?: boolean;
	allowCustomerCreation?: boolean;
	mwccsiteId?: string;
	mwccloginName?: string;
	mwcckey?: string;
	storeWh?: string;
	storeTaxC?: string;
	storeCompanyName?: string;
	storeAddress?: string;
	storeCity?: string;
	storeState?: string;
	storeZip?: string;
	storePhone?: string;
	storeFax?: string;
	storeEmail?: string;
	storeIntroText?: string;
	storeBannerImageUrl?: string;
	storeFrontPageImageUrl?: string;
	defAllowPaymentsProp?: boolean;
	defAllowPaymentsInv?: boolean;
	useCoStatus?: boolean;
	useCoStore?: boolean;
	webPayType?: string;
	websyncdate?: string;
	defShowProp?: boolean;
	defShowInv?: boolean;
	remindToSync?: boolean;
	defUserAllowPayments?: boolean;
	reportAllDetailsType?: number;
	reportImages?: boolean;
	reportShowTab1?: boolean;
	reportShowTab2?: boolean;
	reportShowTab3?: boolean;
	reportShowTab4?: boolean;
	siteUrl?: string;
	inviteMessage?: string;
	propDueDays?: number;
	propRemoveAfterDue?: boolean;
	invDueDays?: number;
	propMessage?: string;
	invMessage?: string;
	showProjCode?: boolean;
	statusEmail?: string;
	ssmaTimeStamp?: string;
	aboutUsImageId?: string;
	bannerImageId?: string;
	batchMessage?: string;
}

export enum POSTING_PROCESS_TYPE {
	ALL_TRANSACTIONS = 'allTransactions',
	SINGLE_TRANSACTION = 'singleTransaction',
	REVISE_TRANSACTION = 'reviseTransaction',
	VOID_TRANSACTION = 'voidTransaction',
	UPDATE_FISCAL_MONTH = 'updateFiscalMonth',
	CHECK_BUDGET = 'checkBudget',
}

export enum API_AUTH_ERROR_MESSAGES {
	INVALID_INSTANCE = 'Instance invalid for Web Pro',
}

export type TGetVendorsReturn = {
	account: string;
	acct: string;
	basecostperhr: number;
	baserateperhr: number;
	bterm1: number;
	bterm2: number;
	bterm3: number;
	category: string;
	depd: boolean;
	depf: false;
	depi: boolean;
	depl: boolean;
	depm: boolean;
	deppct: number;
	designer?: string; // possibly incorrect
	discdays: number;
	discperc: number;
	donotallowonspecs: boolean;
	ein: string;
	emailpo: boolean;
	employee: boolean;
	faxdial: string;
	faxpo: boolean;
	holdpayments: boolean;
	id: number;
	inactive: boolean;
	incometype: number;
	irs1099: true;
	markupdesign: number;
	markupfreight: number;
	markupinstall: number;
	markuplabor: number;
	markupmerc: number;
	notes: string;
	notesrtf: string;
	numAttachments: number;
	onetime: true;
	paydays: number;
	payee: string;
	payeeaddrtype: number;
	payeesameasvendor: boolean;
	poschangedate: boolean;
	poscomperc: number;
	poseditprice: boolean;
	postaxc: null;
	posvoid: boolean;
	poswhaddrtype: number;
	poswhc: null;
	printacctoncheck: true;
	shipvia: string;
	showfreight: boolean;
	sortName1: string;
	sortName2: string;
	terms: string;
	tier2costperhr: number;
	tier2rateperhr: number;
	tier3costperhr: number;
	tier3rateperhr: number;
	typeDesc: string;
	usediscountdesign: number;
	usediscountfreight: number;
	usediscountinstall: number;
	usediscountlabor: number;
	usediscountmerc: number;
	usemarkupinvcat: boolean;
	usemarkupspecs: true;
	venaddrtype: number;
	vendor: string;
	vendorn: string;
	_1099int: boolean;
	_1099rp: string;
};

interface IMaxFilSize {
	maxFileSizeMB: number;
}
interface IVendorInvoiceDetail {
	account?: string;
	txnum: number;
	accountName?: string;
	amount: number;
}

interface IVendorInvoice {
	amount?: number;
	txnum: number;
	type?: number;
	sinvnum?: string;
	sinvdt?: string;
	txdesc?: string;
	discount?: number;
	ponum?: string;
	fiscalmonth?: string;
}

interface IVendorInvoiceDetailProjectPO {
	totalCost?: number;
	refNum?: string;
	description?: string;
	poCost?: number;
	costToDate?: number;
	poQuantity?: number;
	componentType?: number;
	componentTypeDesc?: string;
	quanpaidfor?: number;
}

interface ICheckbookDetailJE {
	account?: string | null;
	accountn?: string | null;
	amount?: number | null;
	txnum?: number | null;
}

interface ICheckbookDetailCheck {
	type: number | null;
	txnum: number | null;
	fiscalmonth: string | null;
	discountamount: number | null;
	amount: number | null;
	pon: string | null;
	txdesc: string | null;
	sinvnum: string | null;
	sinvdt: string | null;
	supplier: string | null;
	checknum: string | null;
	account: string | null;
	userdate: string | null;
}

interface IGeneralLedgerTransactionSearch {
	account?: string;
	userdate?: string;
	supplier?: string;
	checknum?: string;
	depositslip?: string;
	crgroupnum?: number;
}

interface ICheckbookDetailDeposit {
	crgroupnum: number;
	amount: number;
	client: string;
	clientn: string;
	orgname: string;
	checknum: string;
	fiscalmonth: string;
	userdate: string;
	account: string;
	depositslip: string;
}

interface IPaydistDepositCheckbookDetail {
	txnum: number;
	type: number;
	amount: number;
	proj: string;
	projn: string;
	txdesc: string;
	crgroupnum: number;
}

export class ApiService implements HttpService {
	readonly httpWorker = new HttpWorker(HttpWorkerConfigurationFactory.api);

	async get(path: string, filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/${path}/` +
				filter,
			{}
		);
	}

	getProjects(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/projects' +
				filter,
			{}
		);
	}

	async getProjectByCode(code: string, isOption = false, filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		const data: any = await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/projects' +
				`?$filter=proj eq '${encodeURIComponent(code)}' and closeddt eq null${
					filter ? ` ${filter}` : ''
				}`,
			{}
		);

		const dataObj = data![0];

		if (isOption) {
			return dataObj
				? {
						label: `${dataObj?.projn} [${dataObj?.proj}]`,
						value: dataObj?.proj,
						name: dataObj?.projn,
						id: dataObj?.id,
				  }
				: null;
		}

		return dataObj ?? null;
	}

	getProjectsSummaries(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker
			.get(
				HttpWorkerConfigurationFactory.api.baseApiURL +
					'/api/v1/projects/summaries' +
					filter,
				{}
			)
			.then((response: any) => response.results);
	}

	editProject(id: number, params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL + `/api/v1/projects/${id}`,
			params
		);
	}

	getProjectItems(filter = ''): Promise<Item[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/items' + filter,
			{}
		);
	}

	getProjectItemsSummaries(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/items/summaries' +
				filter,
			{}
		);
	}

	createProjectItem(params: {}): Promise<Item> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/items',
			params
		);
	}

	getProjectAvailableRetainer(id: number) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/projects/${id}/availableretainer`,
			{}
		);
	}

	getProjectDepositTotal(projectCode: string, isOpen = true) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/ProjectDeposits/${projectCode}/totals?openOnly=${isOpen}`,
			{}
		);
	}

	getProjectInvoiceTotal(projectCode: string, isOpen = true) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/ProjectInvoices/${projectCode}/totals?openOnly=${isOpen}`,
			{}
		);
	}

	deleteObjects(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/deleteObjects',
			params
		);
	}

	editProjectItem(id: number, params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL + `/api/v1/items/${id}`,
			params
		);
	}

	itemRecalculate(id: number, params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/items/${id}/recalculate`,
			params
		);
	}

	getCatalogs(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/CatalogComponents' +
				filter,
			{}
		);
	}

	getCatalogItems(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/CatalogItems' +
				filter,
			{}
		);
	}

	getClippedItems(filter = ''): Promise<IClippedItemsResponse> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/ItemCaptures' +
				filter,
			{}
		);
	}

	getProjectTickets(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/deliverytickets' +
				filter,
			{}
		);
	}

	getProjectProposals(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/proposals' +
				filter,
			{}
		);
	}

	async getProjectProposalByPropNum(
		propnum: string,
		projCode: string,
		isOption = false,
		filter = ''
	) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		const data: any = await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/proposals?$filter=propnum eq '${encodeURIComponent(
					propnum
				)}' and proj eq '${encodeURIComponent(projCode)}'` +
				filter,
			{}
		);

		const dataObj = data![0];

		if (isOption) {
			return dataObj
				? {
						label: `${dataObj.propnum} ${dataObj?.propname}`,
						value: dataObj?.propnum,
						name: dataObj?.propname ?? '',
						id: dataObj?.id,
				  }
				: null;
		}

		return dataObj ?? null;
	}

	getProjectProposalsSummaries(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker
			.get(
				HttpWorkerConfigurationFactory.api.baseApiURL +
					'/api/v1/proposals/summaries' +
					filter,
				{}
			)
			.then((response: any) => response.results);
	}

	getProjectProposalsHistory(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/historyproposals' +
				filter,
			{}
		);
	}

	getProjectStatus(id: number) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/projects/${id}/status`,
			{}
		);
	}

	getProposalItems(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/proposalItems' +
				filter,
			{}
		);
	}

	getClientInvoiceItems(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/clientinvoices/items' +
				filter,
			{}
		);
	}

	getNotification(notificationId: number) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/notifications/' +
				notificationId,
			{}
		);
	}

	getNotifications() {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/notifications',
			{}
		);
	}

	postNotification(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/notifications',
			params
		);
	}

	getFile(fileId: any) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		return this.httpWorker.getFile(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/files/' + fileId,
			{}
		);
	}

	editFile(
		fileId: string,
		params: { newLabel: string; newLink: string; setLabel: boolean }
	) {
		return this.httpWorker.editFile(
			HttpWorkerConfigurationFactory.api.baseApiURL + `/api/v1/files/${fileId}`,
			params
		);
	}

	getPublicMappings(fileId: string, label: string) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		const params = {
			FileId: fileId,
			Label: label,
		};

		return this.httpWorker.post(
			`${HttpWorkerConfigurationFactory.api.baseApiURL}/api/v1/publicmappings`,
			params
		);
	}

	saveFile(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'multipart/form-data';
		return this.httpWorker.saveFile(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/files',
			params
		);
	}

	deleteFile(fileId: string) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/files/' + fileId,
			{}
		);
	}

	updateFile(
		fileId: string,
		params: {
			file: Blob;
		}
	) {
		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/files/' + fileId,
			params,
			true
		);
	}

	saveItemCaptureImage(itemId: number, params: FormData) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'multipart/form-data';
		return this.httpWorker.saveItemCaptureImage(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/itemcaptures/' +
				itemId +
				'/images',
			params
		);
	}

	deleteItemCaptureImage(path: string) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/itemcaptures/' +
				path,
			{}
		);
	}

	getReportsTree() {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/reportstree',
			{}
		);
	}

	getReport(reportId: number) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/reports/' +
				reportId,
			{}
		);
	}

	getReportParameters(reportId: number) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/reportparameters/reports/' +
				reportId,
			{}
		);
	}

	getReportTypes() {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/reportparameters/types/',
			{}
		);
	}

	resetHeaders() {
		HttpWorkerConfigurationFactory.api.headersApi.responseType = 'json';
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
	}

	getAddresses(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/addresses/' +
				filter,
			{}
		);
	}

	async getAddressByCode(code: string, isOption = false, filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		const data: any = await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/addresses/' +
				`?$filter=code eq '${encodeURIComponent(code)}'${
					filter ? ` and ${filter}` : ''
				}`,
			{}
		);

		const dataObj = data![0];

		if (isOption) {
			return dataObj
				? {
						label: `${dataObj?.attention ? `${dataObj?.attention} ` : ''}[${
							dataObj?.code
						}]`,
						value: dataObj?.code,
						name: dataObj?.attention ?? '',
						id: dataObj?.id,
				  }
				: null;
		}

		return dataObj ?? null;
	}

	getAddressesSummaries(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker
			.get(
				HttpWorkerConfigurationFactory.api.baseApiURL +
					'/api/v1/addresses/summaries' +
					filter,
				{}
			)
			.then((response: any) => response.results);
	}

	getInsurances(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/insurances/' +
				filter,
			{}
		);
	}

	getInsurancesByCode(code = ''): Promise<IInsurance[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/insurances' +
				`?$filter=vendor eq '${encodeURIComponent(code)}'`,
			{}
		);
	}

	getSalesTaxCodes(filter = ''): Promise<[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/salestaxcodes' +
				filter,
			{}
		);
	}

	async getSalesSummaryTaxCodeByCode(
		code: string,
		isOption = false,
		filter = ''
	) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		const data: any = await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/salestaxcodes/summaries' +
				`?$filter=taxc eq '${encodeURIComponent(code)}'${
					filter ? ` and ${filter}` : ''
				}`,
			{}
		);

		const dataObj = data![0];

		if (isOption) {
			return dataObj
				? {
						label: `${dataObj?.taxcn ? `${dataObj?.taxcn} ` : ''}[${
							dataObj?.taxc
						}]`,
						value: dataObj?.taxc,
						name: dataObj?.taxcn ?? '',
						id: dataObj?.taxc,
				  }
				: null;
		}

		return dataObj ?? null;
	}

	async getSalesTaxCodeByCode(code: string, isOption = false, filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		const data: any = await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/salestaxcodes/' +
				`?$filter=taxc eq '${encodeURIComponent(code)}'${
					filter ? ` and ${filter}` : ''
				}`,
			{}
		);

		const dataObj = data![0];

		if (isOption) {
			return dataObj
				? {
						label: `${dataObj?.taxcn ? `${dataObj?.taxcn} ` : ''}[${
							dataObj?.taxc
						}]`,
						value: dataObj?.taxc,
						name: dataObj?.taxcn ?? '',
						id: dataObj?.taxc,
				  }
				: null;
		}

		return dataObj ?? null;
	}

	async getSalesTaxCodeSummaryByCode(code: string) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		const { results }: any = await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/salestaxcodes/summaries' +
				`?$filter=taxc eq '${encodeURIComponent(code)}'`,
			{}
		);

		return results[0] ?? null;
	}

	getSalesTaxCodesSummaries(filter = ''): Promise<[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker
			.get(
				HttpWorkerConfigurationFactory.api.baseApiURL +
					'/api/v1/salestaxcodes/summaries' +
					filter,
				{}
			)
			.then((response: any) => response.results);
	}

	createSalesTaxCodes(params: {}): object {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/salestaxcodes',
			params
		);
	}

	updateProposalDeposit(params: {
		receivedDeposit: number;
	}): Promise<Record<string, never>> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/proposals/updatedeposit',
			params
		);
	}

	resetDepositAllocation(params: {
		ProjectCode: string;
		PropNum: string;
	}): Promise<Record<string, never>> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/proposals/reallocatedeposits',
			params
		);
	}

	patchProposalDeposit(
		id: string,
		params: { manualdep: boolean }
	): Promise<Record<string, never>> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL + `/api/v1/proposals/${id}`,
			params
		);
	}

	editSalesTaxCodes(params: {}): object {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/salestaxcodes',
			params
		);
	}

	getSalesCategories(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/SalesCategories' +
				filter,
			{}
		);
	}

	async getSalesCategoriesSummaries(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		const results = await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/SalesCategories/summaries' +
				filter,
			{}
		);
		return (results as any).results as any;
	}

	async getSalesCategoryByCode(code: string, isOption = false, filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		const data: any = await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/SalesCategories' +
				`?$filter=scat eq '${encodeURIComponent(code)}'${
					filter ? ` and ${filter}` : ''
				}`,
			{}
		);

		const dataObj = data![0];

		if (isOption) {
			return dataObj
				? {
						label: `${dataObj?.scatn ? `${dataObj?.scatn} ` : ''}[${
							dataObj?.scat
						}]`,
						value: dataObj?.scat,
						name: dataObj?.scatn ?? '',
						id: dataObj?.id,
				  }
				: null;
		}

		return dataObj ?? null;
	}

	async getSalesCategorySummaryByCode(
		code: string,
		isOption = false,
		filter = ''
	) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		const data: any = await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/SalesCategories/summaries' +
				`?$filter=scat eq '${encodeURIComponent(code)}'${
					filter ? ` and ${filter}` : ''
				}`,
			{}
		);

		const dataObj = data?.results?.[0];

		if (isOption) {
			return dataObj
				? {
						label: `${dataObj?.value ? `${dataObj?.value} ` : ''}[${
							dataObj?.key
						}]`,
						value: dataObj?.value,
						name: dataObj?.key ?? '',
						id: dataObj?.id,
				  }
				: null;
		}

		return dataObj ?? null;
	}

	getUnitsOfMeasures() {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/UnitsOfMeasures',
			{}
		);
	}

	getVendors(filter = ''): Promise<TGetVendorsReturn[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/vendors' +
				filter,
			{}
		);
	}

	async getVendorByCode(code: string, isOption = false, filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		const data: any = await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/vendors' +
				`?$filter=vendor eq '${encodeURIComponent(code)}'${
					filter ? ` ${filter}` : ''
				}`,
			{}
		);

		const dataObj = data![0];

		if (isOption) {
			return dataObj
				? {
						label: dataObj?.vendorn
							? `${dataObj?.vendorn} [${dataObj?.vendor}]`
							: `[${dataObj?.vendor}]`,
						value: dataObj?.vendor,
						name: dataObj?.vendorn,
						id: dataObj?.id,
				  }
				: null;
		}

		return dataObj ?? null;
	}

	getVendorsSummaries(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker
			.get(
				HttpWorkerConfigurationFactory.api.baseApiURL +
					'/api/v1/vendors/summaries' +
					filter,
				{}
			)
			.then((response: any) => response.results);
	}

	getClients(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/clients' +
				filter,
			{}
		);
	}

	async getClientByCode(code: string, isOption = false, filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		const data: any = await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/clients' +
				`?$filter=client eq '${encodeURIComponent(code)}'${
					filter ? ` ${filter}` : ''
				}`,
			{}
		);

		const dataObj = data![0];

		if (isOption) {
			return dataObj
				? {
						label: `${dataObj?.ClientName} [${dataObj?.client}]`,
						value: dataObj?.client,
						name: dataObj?.ClientName,
						id: dataObj?.id,
				  }
				: null;
		}

		return dataObj ?? null;
	}

	getClientsSummaries(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker
			.get(
				HttpWorkerConfigurationFactory.api.baseApiURL +
					'/api/v1/clients/summaries' +
					filter,
				{}
			)
			.then((response: any) => response.results);
	}

	getClientEmployees(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/employees' +
				filter,
			{}
		);
	}

	async getClientEmployeeByCode(code: string, isOption = false, filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		const data: any = await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/employees' +
				`?$filter=vendor eq '${encodeURIComponent(code)}'${
					filter ? ` ${filter}` : ''
				}`,
			{}
		);

		const dataObj = data![0];

		if (isOption) {
			return dataObj
				? {
						label: `${dataObj?.vendorn} [${dataObj?.vendor}]`,
						value: dataObj?.vendor,
						name: dataObj?.vendorn,
						id: dataObj?.id,
				  }
				: null;
		}

		return dataObj ?? null;
	}

	async getClientEmployeeSummaryByCode(code: string) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		const { results }: any = await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/employees/summaries' +
				`?$filter=vendor eq '${encodeURIComponent(code)}'`,
			{}
		);

		return results[0] ?? null;
	}

	getClientEmployeesSummaries(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker
			.get(
				HttpWorkerConfigurationFactory.api.baseApiURL +
					'/api/v1/employees/summaries' +
					filter,
				{}
			)
			.then((response: any) => response.results);
	}

	getStatusCodes(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/statuscodes' +
				filter,
			{}
		);
	}

	getAppointments() {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/todolists?$orderby=startdatetime desc',
			{}
		);
	}

	async getGLAccounts(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		try {
			return await this.httpWorker.get(
				HttpWorkerConfigurationFactory.api.baseApiURL +
					'/api/v1/generalledgeraccounts' +
					filter,
				{}
			);
		} catch (error: any) {
			// When user role is not authorized
			if (error?.response?.status === 403) {
				return [];
			}
		}
	}

	async getSummaryGLAccounts(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		try {
			const results = await this.httpWorker.get(
				HttpWorkerConfigurationFactory.api.baseApiURL +
					'/api/v1/generalledgeraccounts/summaries' +
					filter,
				{}
			);
			return (results as any).results;
		} catch (error: any) {
			// When user role is not authorized
			if (error?.response?.status === 403) {
				return [];
			}
		}
	}

	async getGLAccountsReportSummaries(filter = ''): Promise<Array<IGLAccount>> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgeraccounts/reportsummaries' +
				filter,
			{}
		);
	}

	createGLAccount(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgeraccounts',
			params
		);
	}

	editGLAccount(id: string, params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/generalledgeraccounts/${id}`,
			params
		);
	}

	transferGLAccount(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgeraccounts/replace',
			params
		);
	}

	getCompany(filter = ''): Promise<Company> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/company' +
				filter,
			{}
		);
	}

	getDesignPayDefaultsForOnboardedCustomers(): Promise<TDesignPayRates> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/designpay/billingprofile/default',

			{}
		);
	}

	getDesignPayDefaultsForNonOnboardedCustomers(): Promise<TDesignPayRates> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/designpay/billingprofile',

			{}
		);
	}

	patchCompany(params: {}): object {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/company/update',
			params
		);
	}

	getCompany2(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/company2' +
				filter,
			{}
		);
	}

	patchCompany2(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/company2',
			params
		);
	}

	getGLTransactionUndepositedPayments(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgertransactions/undepositedpayments' +
				filter,
			{}
		);
	}

	postCashReceiptsUndepositedPaymentsGrid(params: {}): object {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/cashreceipts/undepositedpayments/grid',
			params
		);
	}

	getCashReceiptsUndepositedPaymentsGrid(filter = ''): object {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/cashreceipts/undepositedpayments${filter}`,
			{}
		);
	}

	postCashReceiptsUndepositedPayment(params: {}): object {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/cashreceipts/undepositedpayments/post',
			params
		);
	}

	tagCashReceiptsUndepositedPayment(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/cashreceipts/undepositedpayments/tag`,
			params
		);
	}

	getGLTransactions(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgertransactions' +
				filter,
			{}
		);
	}

	searchGLTransactions(
		filter = ''
	): Promise<IGeneralLedgerTransactionSearch[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgertransactions/search' +
				filter,
			{}
		);
	}

	getGLTransactionsPayablePayments() {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgertransactions/payable/payments',
			{}
		);
	}

	getGLDepartments(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgerdepartments' +
				filter,
			{}
		);
	}

	getGLAccountBudget(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgeraccountbudgets' +
				filter,
			{}
		);
	}

	createGLAccountBudget(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgeraccountbudgets',
			params
		);
	}

	editGLAccountBudget(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgeraccountbudgets',
			params
		);
	}

	getInvoices(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/clientinvoices${filter}`,
			{}
		);
	}

	getInvoicesSummaries(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker
			.get(
				HttpWorkerConfigurationFactory.api.baseApiURL +
					`/api/v1/clientinvoices/summaries${filter}`,
				{}
			)
			.then((response: any) => response.results);
	}

	editInvoices(params = {}) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/clientinvoices/update',
			params
		);
	}

	getNewInvoices(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryclientinvoices' +
				filter,
			{}
		);
	}

	getNewInvoiceItems(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryclientinvoiceitems' +
				filter,
			{}
		);
	}

	deleteNewInvoiceItems(params: {}) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryclientinvoiceitems',
			params
		);
	}

	overrideRefund(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/clientinvoiceitems/override',
			params
		);
	}

	getNewCashReceipts(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporarycashreceipts' +
				filter,
			{}
		);
	}

	getNewPurchaseOrders(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporarypurchaseorders' +
				filter,
			{}
		);
	}

	createPurchaseOrder(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporarypurchaseorders',
			params
		);
	}

	getCashReceipts(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/cashreceipts${filter}`,
			{}
		);
	}

	getCashReceiptDetail(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/cashreceipts/detail${filter}`,
			{}
		);
	}

	temporaryCheckDisbursments(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/temporarycheckdisbursments/checkrun/${filter}`,
			{}
		);
	}

	getPayTypes(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/PayTypes' +
				filter,
			{}
		);
	}

	createPayType(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/paytypes',
			params
		);
	}

	updatePayType(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/paytypes',
			params
		);
	}

	deletePayType(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/paytypes',
			params
		);
	}

	getVendorPayments(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/vendorpayments' +
				filter,
			{}
		);
	}

	createVendorPaymentGrids(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/vendorpayments/grid',
			params
		);
	}

	editVendorPayments(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL + `/api/v1/vendorpayments`,
			params
		);
	}

	postVendorPaymentsOffset() {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/vendorpayments/offset',
			{}
		);
	}

	postVendorPaymentsCC(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/vendorpayments/cc',
			params
		);
	}

	postVendorPaymentsOnline(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/vendorpayments/payonline',
			params
		);
	}

	postVendorPaymentsCheckWire(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/vendorpayments/handcheck',
			params
		);
	}

	getBudgets(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/budgets' +
				filter,
			{}
		);
	}

	updateBudget(params = {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/budgets',
			params
		);
	}

	createBudget(params = {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/budgets',
			params
		);
	}

	deleteBudget(params: {}) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/budgets',
			params
		);
	}

	getSpecificationTemplates(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/SpecificationTemplates' +
				filter,
			{}
		);
	}

	getInventory(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/InventoryStocks' +
				filter,
			{}
		);
	}

	createStockItem(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/InventoryStocks',
			params
		);
	}

	editStockItem(id: number, params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/InventoryStocks/${id}`,
			params
		);
	}

	getItemProposals(filter = ''): object {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/items/proposals' +
				filter,
			{}
		);
	}

	getItemInvoices(filter = ''): object {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/items/invoices' +
				filter,
			{}
		);
	}

	getComponents(filter = ''): Promise<Component[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/components' +
				filter,
			{}
		);
	}

	createComponent(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/components',
			params
		);
	}

	editComponent(id: number, params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/components/${id}`,
			params
		);
	}

	componentRecalculate(
		id: number,
		params: {}
	): Promise<TWhatChangedComponentResponse> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/components/${id}/recalculate`,
			params
		);
	}

	getSalesTaxRules(filter = ''): object {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/salestaxrules' +
				filter,
			{}
		);
	}

	createSalesTaxRule(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/salestaxrules',
			params
		);
	}

	getLocations(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/locations' +
				filter,
			{}
		);
	}

	async getUniqueLocations(filter = '') {
		let data: any = await this.getLocations(filter);
		// Get codes with project and ids with project
		const excludeCodes: any = [];
		const projLocationsIds =
			data
				?.filter((d: any) => {
					if (d.proj !== '$$$$$') {
						excludeCodes.push(d.loc);
					}
					return d.proj !== '$$$$$';
				})
				?.map((d: any) => {
					return d.id;
				}) ?? [];

		// Get list only with project and without duplicate
		data =
			data?.filter((d: any) => {
				return (
					projLocationsIds?.includes(d.id) ||
					(!excludeCodes?.includes(d.loc) && d.proj === '$$$$$')
				);
			}) ?? [];
		// Ensure we remove the duplicate by loc code
		data = uniqBy(data, 'loc');

		return data;
	}

	async getLocationByCode(
		code: string,
		projectId = '',
		isOption = false,
		filter = ''
	) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		const projQry = projectId ? ` or projectId eq ${projectId}` : '';

		const data: any = await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/locations' +
				`?$filter=loc eq '${encodeURIComponent(
					code
				)}' and (projectId eq null${projQry})${filter ? ` ${filter}` : ''}`,
			{}
		);

		const dataObj = data![0];

		if (isOption) {
			return dataObj
				? {
						label: `${dataObj.locn} [${dataObj.loc}]`,
						value: `${dataObj.loc}`,
						name: `${dataObj.locn}`,
						id: dataObj?.id,
				  }
				: null;
		}

		return dataObj ?? null;
	}

	getLocationsSummaries(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker
			.get(
				HttpWorkerConfigurationFactory.api.baseApiURL +
					'/api/v1/locations/summaries' +
					filter,
				{}
			)
			.then((response: any) => response.results);
	}

	postLocation(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/locations',
			params
		);
	}

	getProjectPurchaseOrders(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/projectpurchaseorders' +
				filter,
			{}
		);
	}

	async getProjectPurchaseOrderByProjAndPO({
		project,
		ponum,
		isOption,
		valueKey,
		filter,
	}: {
		project: any;
		ponum: any;
		isOption: boolean;
		valueKey: string;
		filter: string;
	}) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		const data: any = await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/projectpurchaseorders' +
				`?$filter=proj eq '${encodeURIComponent(
					project
				)}' and ponum eq '${encodeURIComponent(ponum)}'${
					filter ? ` ${filter}` : ''
				}`,
			{}
		);

		const dataObj = data![0];

		if (isOption) {
			return dataObj
				? {
						label: `${dataObj?.purchaseOrderName} [${dataObj?.vendorName}]`,
						value: valueKey ? dataObj![valueKey] : dataObj?.id,
						name: dataObj?.purchaseOrderName,
						id: dataObj?.id,
				  }
				: null;
		}

		return dataObj ?? null;
	}

	getProjectPurchaseOrdersSummaries(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker
			.get(
				HttpWorkerConfigurationFactory.api.baseApiURL +
					'/api/v1/projectpurchaseorders/summaries' +
					filter,
				{}
			)
			.then((response: any) => response.results);
	}

	updatePurchaseOrderStatus(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/projectpurchaseorders/updatestatus',
			params
		);
	}

	getReturnItems(filter = ''): object {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/returns/items' +
				filter,
			{}
		);
	}

	createReturnsGrid(params: {}): object {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/returns/grid',
			params
		);
	}

	createReturn(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/returns',
			params
		);
	}

	getReturnsCCTypes() {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/returns/cctypes',
			{}
		);
	}

	editClientInvoicesItems(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/clientinvoices/items',
			params
		);
	}

	createAddress(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/addresses',
			params
		);
	}

	editAddress(id: number, params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL + `/api/v1/addresses/${id}`,
			params
		);
	}

	getSalesTaxCodeCalculations(filter = ''): object {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/salestaxrules' +
				filter,
			{}
		);
	}

	addSalesTaxCodeCalculation(params: {}): object {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/salestaxrules',
			params
		);
	}

	editSalesTaxCodeCalculation(params: {}): object {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/salestaxrules',
			params
		);
	}

	deleteSalesTaxCodeCalculations(path = ''): object {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/salestaxrules' +
				path,
			{}
		);
	}

	addClientInvoiceCredit(params: {}): object {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/clientinvoices/credit',
			params
		);
	}

	async patch(endpoint: string, id = 0, params: any) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/${endpoint}/` +
				id,
			params
		);
	}

	async postJson<T>(endpoint: string, params: any): Promise<T> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.postJson(
			HttpWorkerConfigurationFactory.api.baseApiURL + `/api/v1/${endpoint}`,
			params
		);
	}

	async put(endpoint: string, id = 0, params: any) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.put(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/${endpoint}/` +
				id,
			params
		);
	}

	async delete(endpoint: string, id = 0, params: any) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/${endpoint}/` +
				id,
			params
		);
	}

	getPaymentTypes(): Promise<IPosPayment[]> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/pospaytypes',
			{}
		);
	}

	createPosPaymentType(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/pospaytypes',
			params
		);
	}

	updatePosPaymentType(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/pospaytypes',
			params
		);
	}

	updateTemporaryPurchaseOrder(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporarypurchaseorders',
			params
		);
	}

	deletePosPaymentType(params: {}) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/pospaytypes',
			params
		);
	}

	createCashReceiptMisc(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporarycashreceipts/miscellaneous',
			params
		);
	}

	updateCashReceiptMisc(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporarycashreceipts/miscellaneous',
			params
		);
	}

	getTimeActivities(
		filter = ''
	): Promise<[{ activityn: string; activity: string }]> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/timeactivities${filter}`,
			{}
		);
	}

	async getTimeActivityByCode(code?: string, isOption = false, filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		const data: any = await this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/timeactivities/' +
				`?$filter=activity eq '${encodeURIComponent(
					code ?? ''
				)}' and inactive eq false${filter ? ` and ${filter}` : ''}`,
			{}
		);

		const dataObj = data![0];

		if (isOption) {
			return dataObj
				? {
						label: `${dataObj?.activityn ? `${dataObj?.activityn} ` : ''}[${
							dataObj?.activity
						}]`,
						value: dataObj?.activity,
						name: dataObj?.activityn ?? '',
						id: dataObj?.id,
				  }
				: null;
		}

		return dataObj ?? null;
	}

	getTimeActivitiesSummaries(filter = '') {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker
			.get(
				HttpWorkerConfigurationFactory.api.baseApiURL +
					`/api/v1/timeactivities/summaries${filter}`,
				{}
			)
			.then((response: any) => response.results);
	}

	getCashReceiptsDeposits(filter = '') {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/temporarycashreceipts/deposits${filter}`,
			{}
		);
	}

	getCashReceiptsPayments(filter = '') {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/temporarycashreceipts/payments${filter}`,
			{}
		);
	}

	createCashReceipt(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporarycashreceipts',
			params
		);
	}

	updateCashReceipt(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporarycashreceipts',
			params
		);
	}

	createCashReceiptGrids(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporarycashreceipts/new/grids',
			params
		);
	}

	deleteCashReceipt(param = ''): object {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporarycashreceipts' +
				param,
			{}
		);
	}

	getTransactionCounters() {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/transactioncounters',
			{}
		);
	}

	getTransactionCounterNext(type = '') {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/transactioncounters/next/' +
				type,
			{}
		);
	}

	getTempJournalEntryDetails(filter: string) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/temporaryjournalentrydetails${filter}`,
			{}
		);
	}

	editFinanceCharges(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryjournalentries/editfinancecharges',
			params
		);
	}

	createJournalEntry(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryjournalentries',
			params
		);
	}

	createJournalEntryRevise(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryjournalentries/revise',
			params
		);
	}

	createJournalEntryDetails(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryjournalentrydetails',
			params
		);
	}

	updateJournalEntryDetails(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryjournalentrydetails',
			params
		);
	}

	voidJournalEntry(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryjournalentries/void',
			params
		);
	}

	updateJournalEntry(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryjournalentries',
			params
		);
	}

	postJournalEntryFiscalMonth(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryjournalentries/post',
			params
		);
	}

	deleteTempJournalEntries(param = ''): object {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/TemporaryJournalEntries' +
				param,
			{}
		);
	}

	deleteTempJournalEntryDetails(params = {}) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/TemporaryJournalEntryDetails',
			params
		);
	}

	postSingleTempJournalEntry(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryjournalentries/postsingle',
			params
		);
	}

	updateCashReceiptClientDeposits(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporarycashreceipts/clientdeposits',
			params
		);
	}

	updateCashReceiptPayments(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporarycashreceipts/payments',
			params
		);
	}

	getCheckbooks(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgertransactions/payable/checkbook' +
				filter,
			{}
		);
	}

	voidUnvoidCheckbook(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgertransactions/payable/checkbook/void',
			params
		);
	}

	createCheckbookGrids(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgertransactions/payable/checkbook/grid',
			params
		);
	}

	getJournalEntryCheckbookDetail(filter = ''): Promise<ICheckbookDetailJE[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgertransactions/payable/checkbook/detail/je' +
				filter,
			{}
		);
	}

	getCheckCheckbookDetail(filter = ''): Promise<ICheckbookDetailCheck[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgertransactions/payable/checkbook/detail/check' +
				filter,
			{}
		);
	}

	getDepositCheckbookDetail(filter = ''): Promise<ICheckbookDetailDeposit[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgertransactions/payable/checkbook/detail/deposit' +
				filter,
			{}
		);
	}

	getPaydistDepositCheckbookDetail(
		filter = ''
	): Promise<IPaydistDepositCheckbookDetail[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgertransactions/payable/checkbook/detail/deposit/paydist' +
				filter,
			{}
		);
	}

	getCreditCards(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgertransactions/payable/creditcards' +
				filter,
			{}
		);
	}

	getCreditCardTransactions(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/creditcardtransactions' +
				filter,
			{}
		);
	}

	getCreditCardReconciliations(
		filter = ''
	): Promise<ICreditCardReconciliationHistory[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/creditcardreconciliations' +
				filter,
			{}
		);
	}

	acceptTemporaryCreditCardReconciliation(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporarycreditcardreconciliations/accept',
			params
		);
	}

	startTemporaryCreditCardReconciliation(params: {
		statementEndingDate: string;
		creditCardAccount?: string;
	}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporarycreditcardreconciliations/start',
			params
		);
	}

	getTemporarycreditcardreconciliations(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporarycreditcardreconciliations' +
				filter,
			{}
		);
	}

	getTemporaryCreditCardReconciliationTotals(params: {}): Promise<ICreditCardReconciliationTotals> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporarycreditcardreconciliations/totals',
			params
		);
	}

	updateTemporaryCreditCardReconciliation(recnum = '', params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/temporarycreditcardreconciliations/${recnum}`,
			params
		);
	}

	printCreditCardReconciliation(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/RunReports',
			params
		);
	}

	voidCreditCardReconciliation(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/creditcardreconciliations/void',
			params
		);
	}

	createCreditCardGrids(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/generalledgertransactions/payable/creditcards/grid',
			params
		);
	}

	getAuthToken(params: {}) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersAuth;

		params = {
			...params,
			grant_type: 'password',
			client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
		};

		return this.httpWorker.getAuthToken(params);
	}

	getUserData(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/useraccounts' +
				filter,
			{}
		);
	}

	getCurrentUser() {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/users/current',
			{}
		);
	}

	connectStripe(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;
		const instanceId = getCookie('dmInstanceId');

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/payments/instances?instanceId=' +
				instanceId,
			{ params }
		);
	}

	connectPaymentProcessor(
		paymentProcessor: TProcessor,
		params = {}
	): Promise<TPaymentProcessorResponse> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;
		const instanceId = getCookie('dmInstanceId');

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/payments/instances?instanceId=${instanceId}&paymentProcessor=${paymentProcessor}`,
			params
		);
	}

	getPaymentProcessors(
		processor: TProcessor
	): Promise<TPaymentProcessorResponse> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/WebSettings/payments/instance-account/options?processor=${processor}`,
			{}
		);
	}

	startPaymentProcessorOnboardingSession(
		sessionType: `${PaymentSessionType}`
	): Promise<TPaymentProcessorOnboardingSession> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/DesignPay/session?sessionType=${sessionType}`,
			{}
		);
	}

	getWebSettings(filter = ''): Promise<IWebSettings[]> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/websettings' +
				filter,
			{}
		);
	}

	patchWebSettingsOptions(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/webSettings/payments/instance-account/options',
			params
		);
	}

	postWebSettingsSerialOptions(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + `/api/v1/webSettings`,
			params
		);
	}

	patchWebSettingsSerialOptions(serialNumber = '', params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/webSettings/${serialNumber}`,
			params
		);
	}

	getWebSettingsOptions(filter = '') {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/WebSettings/payments/instance-account/options' +
				filter,
			{}
		);
	}

	editUserData(usercode: number, params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/useraccounts/${usercode}`,
			params
		);
	}

	editUserStatus(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/manageaccount/userstatus`,
			params
		);
	}

	editManageAccount(userId: number, params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/manageaccount/${userId}`,
			params
		);
	}

	deleteUserInvitation(path = ''): object {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/ManageAccount/invitation/' +
				path,
			{}
		);
	}

	postResetPasswordInitiate(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/public/v1/account/resetpassword/initiate',
			params
		);
	}

	postSendInvitation(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;
		const instanceId = getCookie('dmInstanceId');
		params = {
			...params,
		};

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/manageaccount/invitation',
			params
		);
	}

	getCompleteInvitation(token = ''): object {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersAuth;

		return this.httpWorker.getPublic(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/public/v1/account/completeinvite?token=' +
				token,
			{}
		);
	}

	postCompleteInvitation(params: {}) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersAuth;

		return this.httpWorker.postJsonPublic(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/public/v1/account/completeinvite',
			params
		);
	}

	getResetPassword(token = ''): object {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersAuth;

		return this.httpWorker.getPublic(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/public/v1/account/resetpassword?token=' +
				token,
			{}
		);
	}

	postResetPassword(params: {}) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersAuth;

		return this.httpWorker.postJsonPublic(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/public/v1/account/resetpassword',
			params
		);
	}

	patchTemporaryCashReceiptFiscalMonth(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/temporarycashreceipts/fiscalmonth`,
			params
		);
	}

	patchTemporaryCashReceiptHold(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/temporarycashreceipts/hold`,
			params
		);
	}

	editExistingTemporaryCashReceipt(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/Temporarycashreceipts/editexisting',
			params
		);
	}

	postTemporaryCashReceipt(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/TemporaryCashReceipts/post',
			params
		);
	}

	updateTemporaryClientInvoicesFiscalMonth(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/temporaryclientinvoices/updatefiscalmonth`,
			params
		);
	}

	patchTemporaryClientInvoices(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/temporaryclientinvoices`,
			params
		);
	}

	postTemporaryClientInvoice(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/temporaryclientinvoices/post`,
			params
		);
	}

	deleteTemporaryClientInvoices(param = ''): object {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryclientinvoices' +
				param,
			{}
		);
	}

	getItemCapture(id = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/ItemCaptures/' +
				id,
			{}
		);
	}

	createPublicMapping(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/publicmappingbatches/retainers',
			params
		);
	}

	createPublicMappingBatches(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/publicmappingbatches',
			params
		);
	}

	postItemCapture(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + `/api/v1/ItemCaptures`,
			params
		);
	}

	getVendorInvoices(filter = ''): Promise<IVendorInvoice[]> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/vendorinvoices' +
				filter,
			{}
		);
	}

	getExistingVendorInvoiceDetails(filter = '') {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/vendorinvoicedetails' +
				filter,
			{}
		);
	}

	postVendorInvoices(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/vendorinvoices',
			params
		);
	}

	patchVendorInvoices(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/vendorinvoices',
			params
		);
	}

	postVendorInvoiceFiscalMonth(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/vendorinvoices/post',
			params
		);
	}

	getVendorInvoiceDetails(filter = ''): Promise<IVendorInvoiceDetail[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/vendorinvoices/detail' +
				filter,
			{}
		);
	}

	getVendorInvoiceDetailsProjectPO(
		filter = ''
	): Promise<IVendorInvoiceDetailProjectPO[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/vendorinvoices/detail/projectpo' +
				filter,
			{}
		);
	}

	deleteItemCapture(id = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/ItemCaptures/' +
				id,
			{}
		);
	}

	postTemporaryInvoiceDetailsGrid(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryvendorinvoicedetails/new/grid',
			params
		);
	}

	getManageAccount(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/ManageAccount' +
				filter,
			{}
		);
	}

	getUserAccounts() {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL + '/api/v1/useraccounts',
			{}
		);
	}

	transferClippedItem(params = {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/WhiteboardItems/send`,
			params
		);
	}

	deleteClippedItem(id = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/WhiteboardItems/' +
				id,
			{}
		);
	}

	getTimeEntries(filters = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/TimeEntries' +
				filters,
			{}
		);
	}

	postTimeEntryAction(params = {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/timeentries/action',
			params
		);
	}

	getTemporaryVendorInvoicesDeposits(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/temporaryvendorinvoices/deposits/${filter}`,
			{}
		);
	}

	tagTemporaryVendorInvoicesDeposit(keysort: string) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/temporaryvendorinvoices/deposits/${keysort}`,
			{}
		);
	}

	deleteTemporaryVendorInvoices(param = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryvendorinvoices' +
				param,
			{}
		);
	}

	createTemporaryVendorInvoiceGrid(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryvendorinvoicedetails/new/grid',
			params
		);
	}

	createTemporaryVendorInvoiceDeposit(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryvendorinvoicedetails/deposit',
			params
		);
	}

	getTemporaryVendorInvoices(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/temporaryvendorinvoices/${filter}`,
			{}
		);
	}

	createTemporaryVendorInvoice(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryvendorinvoices?forcesave=true',
			params
		);
	}

	updateTemporaryVendorInvoice(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryvendorinvoices?forcesave=true',
			params
		);
	}

	getWireTransferNumberForAccountNumber(accountNumber: string) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/vendorinvoices/wiretransfer/number?accountnumber=${accountNumber}`,
			{}
		);
	}

	getTemporaryInvoiceProjectPurchaseOrder(filter = '') {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/TemporaryVendorInvoiceDetails/projectpo' +
				filter,
			{}
		);
	}

	patchTemporaryInvoiceProjectPurchaseOrder(params = {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryvendorinvoicedetails/projectpo',
			params
		);
	}

	postTemporaryInvoiceCostToDate(params = {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/TemporaryVendorInvoiceDetails/costtodate',
			params
		);
	}

	postVendorDepositsGrid(params = {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/vendordeposits/grid',
			params
		);
	}

	postQuickAddVendorDeposit(params = {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/vendordeposits/quickadd',
			params
		);
	}

	getTemporaryVendorInvoiceDetailsOperatingExpense(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryvendorinvoicedetails/operatingexpense' +
				filter,
			{}
		);
	}

	createTemporaryVendorInvoiceDetailsOperatingExpense(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/temporaryvendorinvoicedetails/operatingexpense`,
			params
		);
	}

	updateTemporaryVendorInvoiceDetailsOperatingExpense(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryvendorinvoicedetails/operatingexpense',
			params
		);
	}

	deleteTemporaryVendorInvoiceDetailsOperatingExpense(params = {}) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;
		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/temporaryvendorinvoicedetails/operatingexpense',
			params
		);
	}

	createTemporaryJournalDeitalsPopulatedDistrbution(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/TemporaryJournalEntries/populatedistributions`,
			params
		);
	}

	getTimezones() {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/company2/timezones',
			{}
		);
	}

	getRemarksSummaries(filter = '') {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker
			.get(
				HttpWorkerConfigurationFactory.api.baseApiURL +
					'/api/v1/remarks/summaries' +
					filter,
				{}
			)
			.then((response: any) => response.results);
	}

	getItemSearch(filter = '') {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/components/specsearch' +
				filter,
			{}
		);
	}

	getAllRoles() {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/authorizationtemplates',
			{}
		);
	}

	validateAttributes(params: {}) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/authorization/validateattributes',
			params
		);
	}

	getAllCompanyInstances(id: number) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/clients/${id}/instances`,
			{}
		);
	}

	getUserInstances(username: string) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/users/${username}/instances`,
			{}
		);
	}

	editUserInstances(id: number, params: {}) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/ManageAccount/${id}`,
			params
		);
	}

	deleteInsurance(insuranceId: number) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.delete(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/Insurances/${insuranceId}`,
			{}
		);
	}

	createInsurance(params: {}) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL + `/api/v1/Insurances`,
			params
		);
	}

	updateInsurance(insuranceNo: number, params: {}) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/Insurances/${insuranceNo}`,
			params
		);
	}

	checkBudgetItem(itemId: number) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/items/${itemId}/recalculate`,
			{ whatChanged: 'iwcCheckBudget' }
		);
	}

	checkBudgetProject(projectId: number) {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/projects/${projectId}/budgetcheck`,
			{}
		);
	}

	checkBudgetVendorInvoice(transactionNumber: string) {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/VendorInvoices/budgetcheck`,
			{
				transactionNumber,
			}
		);
	}

	checkInventoryOnHand(
		params: ICheckInventoryParams
	): Promise<ICheckInventoryResponse> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/InventoryStocks/checkinventoryonhand`,
			params
		);
	}

	createItemFromInventory(
		params: ICreateItemFromInventoryParams
	): Promise<ICreateItemFromInventoryResponse> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/Items/frominventory`,
			params
		);
	}

	getApiBaseUrl(): string {
		return HttpWorkerConfigurationFactory.api.baseApiURL;
	}

	getMaxFileSize(): Promise<IMaxFilSize> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/files/maxfilesize',
			{}
		);
	}

	getReconciliations(filter = ''): Promise<TReconciliation[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/reconciliations${filter !== '' ? `?filter=${filter}` : ''}`,
			{}
		);
	}

	getRecentProjects(filter = ''): Promise<IRecentProject[]> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/projects/recent` +
				filter,
			{}
		);
	}

	getBusinessSummary(): Promise<TBusinessSummaryResponse> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL + `/api/v1/businesssummary`,
			{}
		);
	}

	getProjectSchedules(filter = ''): Promise<TProjectSchedule[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				'/api/v1/projectschedules' +
				filter,
			{}
		);
	}

	patchProjectSchedule(
		proj: string,
		params: TProjectSchedule
	): Promise<TProjectSchedule> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.patch(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/projectschedules/${proj}`,
			params
		);
	}

	getItemImages(filter = ''): Promise<IItemImages[]> {
		HttpWorkerConfigurationFactory.api.headers =
			HttpWorkerConfigurationFactory.api.headersApi;

		return this.httpWorker.get(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/objectfiles` +
				filter,
			{}
		);
	}

	postItemImageOrder(params: TItemOrder): Promise<void> {
		const headers = HttpWorkerConfigurationFactory.api.headersApi;
		headers['Content-Type'] = 'application/json';
		HttpWorkerConfigurationFactory.api.headers = headers;

		return this.httpWorker.post(
			HttpWorkerConfigurationFactory.api.baseApiURL +
				`/api/v1/objectfiles/images/reorder`,
			params
		);
	}
}
