import {
	getCoreRowModel,
	useReactTable,
	flexRender,
	getSortedRowModel,
} from '@tanstack/react-table';
import type { ColumnDef, TableOptions } from '@tanstack/react-table';
import classNames from 'classnames';

type TReactTableProps<T extends object> = {
	data: T[];
	columns: ColumnDef<T>[];
} & TableOptions<T>;
const Table = <
	T extends object & {
		id?: string | number;
	}
>({
	data,
	columns,
	state,
	enableRowSelection,
	getRowId,
	onRowSelectionChange,
}: Omit<TReactTableProps<T>, 'getCoreRowModel'>) => {
	const table = useReactTable({
		data,
		columns,
		state,
		enableRowSelection,
		onRowSelectionChange,
		getRowId,
		getSortedRowModel: getSortedRowModel(),
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<table className="tw-w-full">
			<thead>
				{table.getHeaderGroups().map((headerGroup) => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map((header) => {
							return (
								<th
									className="tw-px-6 tw-py-5 tw-border-b-[1px] tw-border-solid tw-border-[#DEE2E6]"
									key={header.id}
									colSpan={header.colSpan}
									style={{
										width: header.getSize(),
									}}
								>
									{header.isPlaceholder ? null : (
										<div
											className={
												header.column.getCanSort()
													? 'tw-cursor-pointer tw-select-none tw-flex'
													: ''
											}
											onClick={header.column.getToggleSortingHandler()}
											title={
												header.column.getCanSort()
													? header.column.getNextSortingOrder() === 'asc'
														? 'Sort ascending'
														: header.column.getNextSortingOrder() === 'desc'
														? 'Sort descending'
														: 'Clear sort'
													: undefined
											}
										>
											<span>
												{flexRender(
													header.column.columnDef.header,
													header.getContext()
												)}
											</span>
											{{
												asc: (
													<i className="ri-arrow-up-s-fill tw-text-[#7c7c7c]" />
												),
												desc: (
													<i className="ri-arrow-down-s-fill tw-text-[#7c7c7c]" />
												),
											}[header.column.getIsSorted() as string] ?? null}
										</div>
									)}
								</th>
							);
						})}
					</tr>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.map((row, index) => (
					<tr
						key={row.id}
						data-id={row.original.id}
						className="hover:tw-shadow-[0_4px_20px_rgba(0,0,0,0.196)] hover:tw-z-20"
					>
						{row.getVisibleCells().map((cell) => (
							<td
								className={classNames(
									'tw-px-6 tw-py-5',
									index % 2 !== 0
										? 'tw-shadow-[inset_0_0_0_9999px_#faf9f7]'
										: '',
									index % 2 !== 0
										? 'tw-border-t-[1px] tw-border-solid tw-border-[#DEE2E6]'
										: '',
									index % 2 !== 0
										? 'tw-border-b-[1px] tw-border-solid tw-border-[#DEE2E6]'
										: ''
								)}
								key={cell.id}
							>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

Table.displayName = 'Table';

export default Table;
