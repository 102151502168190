import { CellContext } from '@tanstack/react-table';
import SecureLink from 'legacy/app/components/security/SecureLink';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';
import URI from 'legacy/defaults/RoutesDefault';
import { TComponentWithTxNum } from 'legacy/lib/api/hooks/useGetComponents';
import { Button } from 'react-bootstrap';
import { generatePath, Link, LinkProps, useParams } from 'react-router-dom';

type TCustomBootstrapLinkType = React.ComponentClass<LinkProps> &
	keyof JSX.IntrinsicElements;

const TableButtonCell = ({
	info,
}: {
	info: CellContext<TComponentWithTxNum, unknown>;
}) => {
	const { id: projectId, itemId } = useParams();
	return (
		<div className="tw-flex tw-items-center">
			<SecureLink
				title=""
				attributeNo={14}
				attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
				to={
					info.row.original.txnum
						? generatePath(URI.timeEntry.edit, {
								id: info.row.original.txnum,
						  })
						: generatePath(URI.project.newComponentEdit, {
								itemId,
								id: projectId,
								componentId: info.row.original.id,
						  })
				}
				className="tw-text-[#211f21]"
			>
				{info.getValue()}
			</SecureLink>
			{info.row.original.numAttachments > 0 && (
				<Button
					as={Link as unknown as TCustomBootstrapLinkType}
					to={generatePath(URI.project.newComponentEdit, {
						id: info.row.original.projectId,
						itemId: info.row.original.itemId,
						compId: info.row.original.id,
					})}
					variant="ivory"
					size="sm"
					className="tw-border-0 fsx-16 ri-attachment-2 square tw-ml-1 tw-no-underline"
				/>
			)}
		</div>
	);
};
TableButtonCell.displayName = 'TableButtonCell';
export default TableButtonCell;
