import { Button } from 'react-bootstrap';
import cn from 'classnames';
import Stack from 'react-bootstrap/Stack';
import { useEffect, useState } from 'react';
import { useForm, useController, Controller } from 'react-hook-form';
import CurrencyInput from './CurrencyInput';
import { checkReconcile, startReconcile } from './api';
import AccountsDropdown from './AccountsDropdown';
import ReactDatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { EAccountType } from 'legacy/pages/accounts-payable/credit-card/reconcile/AccountsDropdown';
import { useAccounts } from './hooks';
import { useReconcile } from './hooks/useReconcile';

export default function ReconcileSetup({
	onCancel,
	onDownload,
	onAccept,
	onStart,
}) {
	const { started } = useReconcile();
	const [badStart, setBadStart] = useState(false);
	const { data: accounts } = useAccounts(EAccountType.LIABILITY);

	const {
		control,
		formState: { errors, isValid },
		handleSubmit,
		setValue,
		reset,
		getValues,
	} = useForm({
		statementDate: null,
		endingBalance: null,
		account: null,
	});

	const { field } = useController({
		name: 'account',
		control,
		rules: { required: true },
	});

	useEffect(() => {
		if (!started) {
			reset({
				statementDate: null,
				endingBalance: null,
				// Preserve selected account.
				account: getValues().account,
			});
		}
	}, [started]);

	useEffect(() => {
		// Set the first account.
		if (!accounts) return;
		const [firstAccount] = accounts;
		if (firstAccount) {
			setValue('account', {
				label: `[${firstAccount.account}] ${firstAccount.accountn}`,
				value: firstAccount.account,
			});
		}
	}, [accounts, setValue]);

	const onSubmit = async (data) => {
		const response = await checkReconcile(
			data.account.value,
			format(new Date(data.statementDate), 'yyyy-MM-dd')
		);

		if (response.length > 0) {
			setBadStart(true);
		} else {
			setBadStart(false);

			await startReconcile({
				statementEndingDate: format(new Date(data.statementDate), 'yyyy/MM/dd'),
				creditCardAccount: data.account.value,
			});

			onStart(data);
		}
	};

	const selectClasses = cn({
		'border-danger': !!errors.account,
	});
	const statementDateClasses = cn({
		'border-danger': !!errors.statementDate,
	});
	const endingBalanceClasses = cn('form-control', {
		'border-danger': !!errors.endingBalance,
	});

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="bg-ivory light a-header pb-3">
				<Stack direction="horizontal" gap={3} className="align-items-end">
					<div className="w-25">
						Account
						<AccountsDropdown
							{...field}
							className={`${selectClasses} tw-h-10`}
							isDisabled={started}
						/>
					</div>
					<div>
						<span className="tw-block">Statement Date</span>
						<Controller
							control={control}
							name="statementDate"
							rules={{ required: true }}
							render={({ field: { onChange, value } }) => (
								<ReactDatePicker
									dateFormat="MM/dd/yyyy"
									onChange={onChange}
									className={`${statementDateClasses} form-control form-control-sm tw-h-10`}
									showMonthDropdown={true}
									showYearDropdown={true}
									placeholderText="mm-dd-yyyy"
									autoComplete="off"
									selected={value}
									disabled={started}
								/>
							)}
						/>
					</div>
					<div>
						Statement Ending Balance
						<CurrencyInput
							className={endingBalanceClasses}
							disabled={started}
							name="endingBalance"
							control={control}
							rules={{ required: true }}
						/>
					</div>
					<div>
						{!started ? (
							<Button variant="primary" type="submit" disabled={!isValid}>
								Start Reconcile
							</Button>
						) : (
							<Stack direction="horizontal" gap={3}>
								<Button variant="outline-dark ms-auto" onClick={onCancel}>
									Cancel
								</Button>
								<Button variant="primary" onClick={onAccept}>
									Accept Reconciliation
								</Button>
								<Button variant="outline-dark ms-auto" onClick={onDownload}>
									Get PDF
								</Button>
							</Stack>
						)}
					</div>
				</Stack>
				{badStart && (
					<p className="mt-4 alert alert-danger">
						There is already a reconciliation for this account that is newer or
						the same as this one. Please use the history button to view
						completed reconciliations.
					</p>
				)}
			</div>
		</form>
	);
}
